/* You can add global styles to this file, and also import other style files */
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "~fullcalendar/dist/fullcalendar.css";
@import "assets/scss/functions";
@import "assets/scss/variables";
@import "assets/scss/mixins";
@import "assets/scss/utilities";

// Layout & components
@import "assets/scss/root";
@import "assets/scss/reboot";
@import "assets/scss/type";
@import "assets/scss/images";
@import "assets/scss/containers";
@import "assets/scss/grid";
@import "assets/scss/tables";
@import "assets/scss/forms";
@import "assets/scss/buttons";
@import "assets/scss/transitions";
@import "assets/scss/dropdown";
@import "assets/scss/button-group";
@import "assets/scss/nav";
@import "assets/scss/navbar";
@import "assets/scss/card";
@import "assets/scss/accordion";
@import "assets/scss/breadcrumb";
@import "assets/scss/pagination";
@import "assets/scss/badge";
@import "assets/scss/alert";
@import "assets/scss/progress";
@import "assets/scss/list-group";
@import "assets/scss/close";
@import "assets/scss/toasts";
@import "assets/scss/modal";
@import "assets/scss/tooltip";
@import "assets/scss/popover";
@import "assets/scss/carousel";
@import "assets/scss/spinners";
@import "assets/scss/offcanvas";
@import "assets/scss/placeholders";

// Helpers
@import "assets/scss/helpers";

// Utilities
@import "assets/scss/utilities/api";
// scss-docs-end import-stack

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  max-width: 160px;
  // overflow: scroll;
}

.orange {
  color: orange;
}

.search-container {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/assets/images/search-image.jpg');
  text-align: center;
  height: 190px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.banner-centered {
  color: antiquewhite;
  width: 100%;
}

.four-line-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  cursor: pointer;
}

.less-text {
  #more {
    display: none;
  }
}

.more-text {

  // #more{
  //   display: inline;
  // }
  #dots {
    display: none;
  }
}

.header-image {
  position: relative;

  & .page-title {
    position: absolute;
    bottom: 0;
    left: 50%;
    background: $primary;
    padding: $spacer $spacer*2;
    text-transform: uppercase;
    color: $white;
    font-weight: 700;
    border-radius: 0 0 0 8px;
    transform: translateX(-50%);
    display: none;
  }

  & img {
    border-radius: $border-radius-lg;
    width: 100%;
    max-height: 60vh;
    object-fit: cover;
    object-position: top;
  }
}

.pipes {

  & span {

    &::after {
      content: "|";
      padding: 0 $spacer;

    }
    &:last-child::after {
      display: none;
    }
  }
}

.menu-cust{
  li {
      background: url("/assets/images/chevron.svg") no-repeat;
      list-style: none;
      background-position: 0 10px;
      padding-left: 10px;
      cursor: pointer;
  }
  a.nav-link.active {
      font-weight: 600;
  }
}

.collapse-cust{
  a{
    font-size:0.85rem;
    margin-left: 10px;
  }
}

.fc-time{
  display: none;
}

.bg-blue{
  background-color: #1D3A7C !important;
}
.btn-bg{
  background-color: #1D3A7C !important;
  color: white !important;
}

.index{
  z-index:1;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-section,
  .print-section * {
    visibility: visible;
  }
  .print-section {
    position: absolute;
    left: 0;
    top: 0;
  }
}